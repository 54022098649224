// src/utils/encryption.js
import CryptoJS from 'crypto-js';

const secretKey = CryptoJS.enc.Utf8.parse("a1b2c3d4e5f6g7h8");
const iv = CryptoJS.enc.Utf8.parse("8h7g6f5e4d3c2b1a");

export const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), secretKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  }).toString();
};

export const decryptPassword = (encryptedPassword) => {
  const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return bytes.toString(CryptoJS.enc.Utf8);
};
