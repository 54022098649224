import React,{useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {IMAGES, SVGICON} from '../constant/theme';
import { Logout } from '../../admin/store/actions/AuthActions';
import { connect, useDispatch } from 'react-redux';


export function  NavMenuToggle(){
	setTimeout(()=>{	
		let sidebarBlog = document.querySelector("#frontSidebar");
        let sidebarMenu = document.getElementById('SidebarMenu');
		if(sidebarBlog.classList.contains('active')){
			sidebarBlog.classList.remove("active");
            sidebarMenu.classList.remove("open");
		}else{
			sidebarBlog.classList.add("active");
			sidebarMenu.classList.add("open");
		}
	},200);
}

const headerMenu = [
    {title: "Pos", to:"/", icon: SVGICON.Notes }
];

const Header = () => {
    const [itemActive, setItemActive] = useState('/');
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const dispatch = useDispatch();
    const nav = useNavigate();

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(Logout(nav));
    };
    //console.log(itemActive);
    //console.log('itemActive');
    
    return (
        <>
              <header className="site-header mo-left header style-1">
                {/* <!-- Main Header --> */}
                    <div className="sticky-header main-bar-wraper navbar-expand-xl">
                        <div className="main-bar clearfix ">
                            <div className="container-fluid clearfix">
                                {/* <!-- Website Logo --> */}
                                <div className="logo-header mostion logo-dark">
                                    <Link to={"/dashboard"}><img src={IMAGES.LogoText} alt="" /></Link>
                                </div>
                                {/* <!-- Nav Toggle Button --> */}
                                <button className="navbar-toggler collapsed navicon justify-content-end" type="button"
                                    
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                {/* <!-- Extra Nav --> */}
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <div className="profile-box" onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                                            <div className="header-info">
                                                <span>Ekiti Government.</span>
                                                <small>Admin</small>
                                            </div>
                                            <div className="img-bx">
                                                <img src={IMAGES.Avatar1} alt="Profile" />
                                            </div>
                                        </div>
                                        {dropdownVisible && (
                                            <div className="dropdown-menu show" style={{ position: 'absolute', right: 0, top: '100%', marginTop: '10px' }}>
                                                <button 
                                                    onClick={handleLogout} 
                                                    className="dropdown-item text-danger"
                                                >
                                                    Logout
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                <div 
                                    className={`sidebar-menu`}                                    
                                    onClick={()=>{                                        
                                        NavMenuToggle();
                                    }}
                                >
                                    <div
                                        className={`menu-btn navicon`}
                                        id="SidebarMenu"
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                
                                <div className="header-nav navbar-collapse collapse" id="navbarNavDropdown">
                                    <div className="logo-header">
                                        <img src={IMAGES.Avatar1} alt="" />
                                    </div>
                                    {/* <ul className="nav navbar-nav navbar navbar-left">	
                                        {headerMenu.map((data, ind)=>(
                                            <li className={`${itemActive === data.to ? 'active' : '' }`} key={ind}>
                                                <Link to={data.to}
                                                    onClick={()=>setItemActive(data.to)}
                                                >
                                                    {data.icon}
                                                    {" "}{data.title}
                                                </Link>
                                            </li>
                                        ))}                                        
                                    </ul> */}
                                </div>  
                            </div>
                        </div>
                    </div>
                    {/* <!-- Main Header End --> */}
                </header>  
        </>
    );
};

export default Header;