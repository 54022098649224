import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SVGICON } from '../constant/theme';

import { NavMenuToggle } from './Header';

const MenuItems = [
    { title: "Dashboard", to: "/dashboard", icon: SVGICON.Dashboard },
    {
        title: "Vendors",
        to: "#",  
        icon: SVGICON.People,
        subItems: [
            { title: "Approved Vendors", to: "/approved-vendors" },
            { title: "Pending Vendors", to: "/pending-vendors" },
        ]
    },
    { 
        title: "Food Items", 
        to: "#", 
        icon: SVGICON.FoodItem,
        subItems: [
            { title: "Approved Products", to: "/approved-merchant-products" },
            { title: "Pending Products", to: "/pending-approval" },
        ]
    },
    { title: "Transactions", to: "/front-transactions", icon: SVGICON.Wallet },
    { title: "Order Complaints", to: "/front-reviews", icon: SVGICON.Review },
    // { title: "Authentication", to: "/front-authentication", icon: SVGICON.Authent },
    { title: "Settings", to: "/front-setting", icon: SVGICON.Setting },
    // { title: "Support", to: "/front-support", icon: SVGICON.Support },
    // { title: "Terms & Conditions", to: "/front-terms_conditions", icon: SVGICON.Terms },
];

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleDropdown = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <>
            <div className="menu-sidebar" id="frontSidebar">
                <div className="contact-box">
                    <ul>
                        {MenuItems.map((data, index) => (
                            <li className="nav-item mb-2" key={index}>
                                {data.subItems ? (
                                    <div onClick={() => toggleDropdown(index)} className="nav-link" style={{marginLeft: '13px'}}>
                                        {data.icon}
                                        {" "}{data.title}
                                    </div>
                                ) : (
                                    <Link to={data.to} className="nav-link">
                                        {data.icon}
                                        {" "}{data.title}
                                    </Link>
                                )}
                                {data.subItems && activeIndex === index && (
                                    <ul className="submenu">
                                        {data.subItems.map((subItem, subIndex) => (
                                            <li className="nav-item" key={subIndex}>
                                                <Link to={subItem.to} className="nav-link">
                                                    {subItem.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="menu-close"
                onClick={() => {
                    NavMenuToggle();
                }}
            ></div>
        </>
    );
};

export default Sidebar;
