
import React, {useEffect, useState, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { SVGICON } from '../constant/theme';
import { ArrowBack, Check, Cancel } from '@mui/icons-material';
import { Row, Col, Card, Button, Dropdown, ButtonGroup } from 'react-bootstrap'



const MerchantKyc = () => {

    const navigate = useNavigate();

    const [data, setData] = useState(
		document.querySelectorAll("#example7_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
    // useEffect(() => {
    //   setData(document.querySelectorAll("#example7_wrapper tbody tr"));
    //   //chackboxFun();
	// }, [test]);

  
   // Active pagginarion
    // activePag.current === 0 && chageData(0, sort);
   // paggination
    let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="form-head dashboard-head d-md-flex d-block mb-3 align-items-start justify-content-between">
                        <div className="buttons" >
                          <Button className='btn btn-light btn-sm' type='button' onClick={() => navigate(-1)}>
                             < ArrowBack/> Back
                          </Button>
                        </div>
                    </div>
                    <div className='row'>
                    <div className="col-12">
                        <div className='card'>
                            <div className='card-header'>
                                <div className="d-flex d-block mb-1 w-100 align-items-start justify-content-between">
                                    <h3 className="me-auto mt-3"> Merchant Information </h3>
                                    <div className="back-button">
                                        <Button className='btn btn-success' type='button'>
                                            Approve
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card-text">
                                    <div className="table-responsive">
                                        <div  id="example7_wrapper" className="dataTables_wrapper no-footer">  
                                            <table id="example5" className="display mb-4 w-100 defaultTable dataTablesCard dataTable no-footer" style={{minWidth:"845px"}}>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>Business Name:</strong></td>
                                                        <td> Alayo Ventures </td>										
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Contact Person:</strong></td>
                                                        <td> OLuwatomisin Adigun </td>										
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Email:</strong></td>
                                                        <td> alayoventures@gmail.com </td>										
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Phone Number:</strong></td>
                                                        <td> 08012341234 </td>										
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Address:</strong></td>
                                                        <td> Iyemero Farm Settlement, Via Itapaji Ekiti Town  </td>										
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Status:</strong></td>
                                                        <td> <p className='text-warning'> Pending </p>  </td>										
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='card'>
                                <div className='card-header'>
                                <h3 className="me-auto mt-3"> Kyc Information </h3>
                                </div>
                                <div className='card-body'>
                                    <div className='card-text'>
                                        <div className="table-responsive">
                                            <div  id="example7_wrapper" className="dataTables_wrapper no-footer">  
                                                <table id="example5" className="display mb-4 h-100 w-100 defaultTable dataTablesCard dataTable no-footer" style={{minWidth:"845px"}}>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">S/N</th>
                                                            <th className="text-center">Item</th>
                                                            <th className="text-center">Value</th>
                                                            <th className="text-center">Status</th>
                                                            <th className="text-center">CREATED</th>
                                                            <th className="text-center">ACTION</th>
                                                            <th className="text-center text-end">REASON</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">1</td>
                                                            <td className="text-center"><strong>BVN</strong></td>
                                                            <td className="text-center">22443311555</td>
                                                            <td> <p className='text-warning'> Pending </p> </td>
                                                            <td>12 June 2020 12:30 pm</td>
                                                            <td>
                                                                <div className='buttons'>
                                                                    <button type="button" class="btn btn-outline-success btn-sm m-2"> <Check/> Accept </button>
                                                                    <button type="button" class="btn btn-outline-danger btn-sm m-2"> <Cancel/> Reject</button>
                                                                </div>
                                                            </td>	
                                                            <td>
                                                                <div className='form-group mt-2'>
                                                                <textarea className='form-control' rows={4} cols={20} placeholder='Enter your reason for rejection here...'></textarea>
                                                                </div>	
                                                            </td>								
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">2</td>
                                                            <td className="text-center"><strong>BANK NAME</strong></td>
                                                            <td className="text-center">FIRST BANK</td>
                                                            <td> <p className='text-warning'> Pending </p> </td>
                                                            <td>12 June 2020 12:30 pm</td>
                                                            <td>
                                                                <div className='buttons'>
                                                                    <button type="button" class="btn btn-outline-success btn-sm m-2"> <Check/> Accept </button>
                                                                    <button type="button" class="btn btn-outline-danger btn-sm m-2"> <Cancel/> Reject</button>
                                                                </div>
                                                            </td>	
                                                            <td>
                                                                <div className='form-group mt-2'>
                                                                <textarea className='form-control' rows={4} cols={20} placeholder='Enter your reason for rejection here...'></textarea>
                                                                </div>	
                                                            </td>										
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">3</td>
                                                            <td className="text-center"><strong>BANK ACCOUNT NAME</strong></td>
                                                            <td className="text-center">Adebowale Lawal</td>
                                                            <td> <p className='text-warning'> Pending </p> </td>
                                                            <td>12 June 2020 12:30 pm</td>
                                                            <td>
                                                                <div className='buttons'>
                                                                    <button type="button" class="btn btn-outline-success btn-sm m-2"> <Check/> Accept </button>
                                                                    <button type="button" class="btn btn-outline-danger btn-sm m-2"> <Cancel/> Reject</button>
                                                                </div>
                                                            </td>	
                                                            <td>
                                                                <div className='form-group mt-2'>
                                                                <textarea className='form-control' rows={4} cols={20} placeholder='Enter your reason for rejection here...'></textarea>
                                                                </div>	
                                                            </td>											
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4</td>
                                                            <td className="text-center"><strong>BANK ACCOUNT NUMBER</strong></td>
                                                            <td className="text-center">0123456789</td>
                                                            <td> <p className='text-warning'> Pending </p> </td>
                                                            <td>12 June 2020 12:30 pm</td>
                                                            <td>
                                                                <div className='buttons'>
                                                                    <button type="button" class="btn btn-outline-success btn-sm m-2"> <Check/> Accept </button>
                                                                    <button type="button" class="btn btn-outline-danger btn-sm m-2"> <Cancel/> Reject</button>
                                                                </div>
                                                            </td>	
                                                            <td>
                                                                <div className='form-group mt-2'>
                                                                    <textarea className='form-control' rows={4} cols={20} placeholder='Enter your reason for rejection here...'></textarea>
                                                                </div>	
                                                            </td>										
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>
        </>
    );
};


export default MerchantKyc