import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useNavigate } from "react-router-dom";


import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



export function signupAction(data, navigate) {
	
    return (dispatch) => {
        signUp(data)
        .then((response) => {
            console.log('reg',response.data);
            
            localStorage.setItem("token",response.data.token)
            // runLogoutTimer(
            //     dispatch,
            //     response.data.expiresIn * 1000,
            // );
            dispatch(confirmedSignupAction(response.data));
            toast.success(response.data.message); 
            setTimeout(() => {
                navigate('/login');
            }, 14800);
        })
        .catch((error) => {
            const errorData = error.response ? error.response.data : error;          
            const errorMessage = formatError( errorData );
            dispatch(signupFailedAction(errorMessage));
            toast.error(errorMessage); 
            throw error; 
        });
    };
}

export function Logout(navigate) {
	localStorage.removeItem('token');
    navigate('/login');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, encryptedPassword, navigate) {
    return (dispatch) => {
        return login(email, encryptedPassword) // Return the promise
            .then((response) => {
                localStorage.setItem("token",response.data.token)
                
                // saveTokenInLocalStorage(response.data.token);
                // runLogoutTimer(
                //     dispatch,
                //     response.data.expiresIn * 1000,
                //     navigate,
                // );
                dispatch(loginConfirmedAction(response.data));
                toast.success('Login successful!'); 
                navigate('/dashboard');                
            })
            .catch((error) => {    
                const errorData = error.response ? error.response.data : error;          
                const errorMessage = formatError( errorData );
                dispatch(loginFailedAction(errorMessage));
                toast.error(errorMessage); 
                throw error; 
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
