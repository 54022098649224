import React, {useEffect, useState, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { SVGICON } from '../../constant/theme';
import { ArrowBack, Check, Cancel } from '@mui/icons-material';
import { Button } from 'react-bootstrap';


const tableDataBlog = [
    {title:"50kg Rice"},
    {title:"Kings Groundnut Oil"},
    {title:"Oloyin Beans (per derica)"},
    {title:"honeywell semolina(1kg)"},
    {title:"Golden Penny Semovita (1kg)"},
    {title:"Black Elubo (Paint)"},
    {title:"Yam (big size)"},
    {title:"Corn (Paint Size)"},
    
];

const PendingProducts = () => {
    const navigate = useNavigate();

    const [data, setData] = useState(
		document.querySelectorAll("#example7_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
    useEffect(() => {
      setData(document.querySelectorAll("#example7_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

  
   // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
   // paggination
    let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
                        <h2 className="dashboard-title me-auto">
                            <Button className='btn btn-light btn-sm' type='button' onClick={() => navigate(-1)}>
                                < ArrowBack/> Back
                            </Button>
                        </h2>
                        {/* <div className="buttons" >
                          <Button className='btn btn-light btn-sm' type='button' onClick={() => navigate(-1)}>
                             < ArrowBack/> Back
                          </Button>
                        </div> */}
                        <div className="input-group search-area">
                            <input type="text" className="form-control" placeholder="Search here..." />
                            <span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <div  id="example7_wrapper" className="dataTables_wrapper no-footer">  
                                    <table id="example5" className="display mb-4 w-100 defaultTable dataTablesCard dataTable no-footer" style={{minWidth:"845px"}}>
                                        <thead>
                                            <tr>
                                                <th>ITEM NAME</th>
                                                <th>CATEGORY</th>
                                                <th>PRICE</th>
                                                <th>QUANTITY</th>
                                                <th>STATUS</th>
                                                <th>ACTION</th>
                                                <th>REASON</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableDataBlog.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td><strong>{data.title}</strong></td>
                                                    <td>Raw Food</td>
                                                    <td>₦1500.00</td>
                                                    <td> 100 </td>
                                                    <td> <p className='text-warning'> Pending </p> </td>
                                                    <td>
                                                        <div className='buttons'>
                                                            <button type="button" class="btn btn-outline-success btn-sm m-2"> <Check/> Accept </button>
                                                            <button type="button" class="btn btn-outline-danger btn-sm m-2"> <Cancel/> Reject</button>
                                                        </div>
                                                    </td>	
                                                    <div className='form-group mt-2'>
                                                        <textarea className='form-control' rows={4} cols={20} placeholder='Enter your reason for rejection here...'></textarea>
                                                    </div>											
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                        <div className="dataTables_info">
                                            Showing {activePag.current * sort + 1} to{" "}
                                            {data.length > (activePag.current + 1) * sort
                                                ? (activePag.current + 1) * sort
                                                : data.length}{" "}
                                            of {data.length} entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers mb-0"
                                            id="application-tbl1_paginate"
                                        >
                                            <Link
                                                className="paginate_button previous "
                                                to="/front-food-items"
                                                onClick={() =>
                                                    activePag.current > 0 &&
                                                    onClick(activePag.current - 1)
                                                }
                                            >
                                                Previous
                                            </Link>
                                            <span>
                                                {paggination.map((number, i) => (
                                                    <Link
                                                        key={i}
                                                        to="#"
                                                        className={`paginate_button  ${
                                                            activePag.current === i ? "current" : ""
                                                        } `}
                                                        onClick={() => onClick(i)}
                                                    >
                                                        {number}
                                                    </Link>
                                                ))}
                                            </span>

                                            <Link
                                                className="paginate_button next"
                                                to="#"
                                                onClick={() =>
                                                    activePag.current + 1 < paggination.length &&
                                                    onClick(activePag.current + 1)
                                                }
                                            >
                                                Next
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>
        </>
    );
};


export default PendingProducts