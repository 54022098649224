import Header from './Header'
import Sidebar from './Sidebar'

function Layout() {
  return (
    <>
        <Header />
        <Sidebar/>
    </>
  )
}

export default Layout
