import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate} from 'react-router-dom'
import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';
import { encryptPassword } from "../../../utils/encryption";
import logo from "../../images/ekiti_small.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//


function Login (props) {
    const [email, setEmail] = useState('demo@example.com');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('123456');
    const dispatch = useDispatch();
	const nav = useNavigate();

	const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

	async function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
	
		// Validate email
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		} else if (!validateEmail(email)) {
			errorObj.email = 'Invalid Email Format';
			error = true;
		}
	
		// Validate password
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
	
		setErrors(errorObj);
	
		if (error) {
			return;
		}
	
		const encryptedPassword = encryptPassword(password);
		dispatch(loadingToggleAction(true));
	
		try {
			await dispatch(loginAction(email, encryptedPassword, nav));
		} catch (err) {
			console.error("Login failed:", err);
		} finally {
			dispatch(loadingToggleAction(false));
		}
	}
	

  return (
		<div className="login-form-bx">
			<ToastContainer /> 
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 col-md-7 box-skew d-flex">
						<div className="authincation-content">
							<div className="mb-4">
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<img src={logo} alt="Logo" className="logo-icon" style={{ width: '80px', height: '80px', marginBottom: '15px' }} />
								</div>
								<h4 className="text-center mb-1 font-w600">Welcome to Tabul for Government</h4>
								<p className="text-center">Sign into your account</p>
							</div>
							{/* {props.errorMessage && (
								<div className='bg-red-300 text-red-900 border border-red-9Davur00 p-1 my-2'>
									{props.errorMessage}
								</div>
							)}
							{props.successMessage && (
								<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
									{props.successMessage}
								</div>
							)} */}
							<form onSubmit={onLogin}>
								<div className="form-group">
									<label className="mb-2 ">
										<strong className="">Email</strong>
									</label>
									<input 
										type="email" 
										className="form-control" 
										placeholder={email} 
										onChange={(e) => {
											setEmail(e.target.value);
											if (!validateEmail(e.target.value)) {
												setErrors(prev => ({ ...prev, email: 'Invalid Email Format' }));
											} else {
												setErrors(prev => ({ ...prev, email: '' }));
											}
										}}
									/>
									{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
								</div>
								<div className="form-group">
									<label className="mb-2 "><strong className="">Password</strong></label>
									<input type="password" className="form-control" placeholder={password} onChange={(e) => setPassword(e.target.value)}/>
									{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
								</div>
								<div className="form-row d-flex justify-content-between mt-4 mb-2">
									<div className="form-group">
										<div className="custom-control custom-checkbox ms-1 ">
											<input type="checkbox" className="form-check-input" id="basic_checkbox_1"/>
											<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
										</div>
									</div>
								</div>
								<div className="text-center">
									<button type="submit" className="btn btn-block" style={{backgroundColor: '#E85E65', color: 'white'}}>Sign In</button>
								</div>
							</form>
							<div className="new-account mt-2">
								<p className="mb-0">Don't have an account?{" "}
									<Link className="text-black" to="/registration">Sign up</Link>
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-5 d-flex box-skew1">
						<div className="inner-content align-self-center">
              				
            			</div>
					</div>
				</div>
			</div>
		</div>
		
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);